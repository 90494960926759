<template>
  <a-modal
      v-model="visible"
      @ok="handleOk"
      @close="handelClose"
      @cancel="handelClose"
      :centered="true"
      class="add-submission"
      :cancelButtonProps="{ props: { loading: submitLoading } }"
      :okButtonProps="{ props: { loading: submitLoading } }"
  >
    <h3>Abstract Submission</h3>
      <a-form-model ref="abstractForm" :model="form" :rules="rules" :label-col="{span: 4}" :wrapper-col="{span: 20}" layout="horizontal">
        <a-form-model-item label="Title" prop="title">
          <a-input type="text" v-model="form.title" placeholder="please input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Author" prop="author">
          <a-input type="text" v-model="form.author" placeholder="please input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Country" prop="country">
          <search-select :options="countries" v-model="form.country" :placeholder="`Please select`"></search-select>
        </a-form-model-item>
        <a-form-model-item label="City" prop="city">
          <a-input type="text" v-model="form.city" placeholder="please input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Affiliation" prop="affiliation">
          <a-input type="text" v-model="form.affiliation" placeholder="please input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Email" prop="email">
          <a-input type="text" v-model="form.email" placeholder="please input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Mobile" prop="mobile">
          <a-input type="text" v-model="form.mobile" placeholder="please input"></a-input>
        </a-form-model-item>
        <a-form-model-item label="Topic" prop="topic">
          <a-select
              v-model="form.topic"
              placeholder="please select"
          >
            <a-select-option
                v-for="(topic, index) in topicList"
                :key="index"
                :label="topic.label"
                :value="topic.value"
            >{{topic.label}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="Type" prop="type">
          <a-select
              v-model="form.type"
              placeholder="please select"
          >
            <a-select-option
                v-for="(topic, index) in typeList"
                :key="index"
                :label="topic.label"
                :value="topic.value"
            >{{topic.label}}</a-select-option>
          </a-select>
        </a-form-model-item>
        <a-form-model-item label="File" prop="documentInfo">
          <a-upload
              :action="uploadUrl"
              accept="application/pdf"
              method="post"
              :remove="handleRemove"
              :before-upload="beforeUpload"
              @change="changeUploadStatus"
              :default-file-list="fileList"
              :file-list="fileList"
          >
            <a-button :loading="uploading"> <a-icon type="upload" /> Select File </a-button>
          </a-upload>
        </a-form-model-item>
      </a-form-model>
  </a-modal>
</template>

<script>
import {formModalWarnAndScroll} from "@/utils/util";
import {addAbstract, getAbstractInfoById, modifyAbstract} from "@/api/abstract";
import {countries} from "../../../assets/js/country/country";
import { countriesCode } from "../../../assets/js/country/countryCode";

export default {
  name: "AbstrctForm",
  components: {
    SearchSelect: () => import('@/components/SearchSelect')
  },
  data() {
    const validateEmail = (rule, value, callback) => {
      if (!value) {
        callback(new Error());
      } else if (!(/^[A-z0-9]+([-._][A-z0-9]+)*@([a-z0-9]+(-[a-z0-9]+)*\.)+[a-z]{2,4}$/.test( value ) && /^(?=.{1,64}@.{4,64}$)(?=.{6,100}$).*/.test( value ))) {
        callback(new Error());
      } else {
        callback();
      }
    };
    const validateDocumentInfo = (rule, value, callback) => {
      if (value.length === 0) {
        callback(new Error("Please upload file."));
      } else {
        callback();
      }
    };
    return {
      uploadUrl: process.env.VUE_APP_API_UPLOAD_URL,
      form: {
        id: "",
        author: "",
        country: undefined,
        city: "",
        affiliation: "",
        email: "",
        mobile: "",
        countryCode: "",
        title: "",
        type: undefined,
        topic: undefined,
        documentInfo: [],
      },
      countries,
      countriesCode,
      visible: false,
      uploading: false,
      submitLoading: false,
      fileList: [],
      topicList: [
        { value: 0, label: "Adult Cardiac" },
        { value: 1, label: "Thoracic" },
        { value: 2, label: "Pediatric" },
        { value: 3, label: "Heart Failure" }
      ],
      typeList: [
        { value: 0, label: "Oral" },
        { value: 1, label: "Poster" },
      ],
      rules: {
        title: [
          { required: true, message: 'Please input title.', trigger: 'blur' },
        ],
        author: [
          { required: true, message: 'Please input author.', trigger: 'blur' },
        ],
        country: [
          { required: true, message: 'Please select country.', trigger: 'change' },
        ],
        city: [
          { required: true, message: 'Please input city.', trigger: 'blur' },
        ],
        affiliation: [
          { required: true, message: 'Please input affiliation.', trigger: 'blur' },
        ],
        email: [
          { required: true, validator: validateEmail, message: 'Please input email address.', trigger: 'blur' },
        ],
        mobile: [
          { required: true, message: 'Please input mobile.', trigger: 'blur' },
        ],
        topic: [
          { required: true, message: 'Please select topic.', trigger: 'change' },
        ],
        type: [
          { required: true, message: 'Please select type.', trigger: 'change' },
        ],
        documentInfo: [
          { required: true, validator: validateDocumentInfo, message: 'Please upload file.', trigger: 'change' },
        ],
      }
    }
  },
  watch: {
    'form.country': {
      handler(val) {
        if (val) {
          this.countriesCode.forEach((item)=> {
            if (item.name === val) {
              this.form.countryCode = item.dialCode
            }
          })
        } else {
          this.form.countryCode = ""
        }
      }
    }
  },
  methods: {
    initAbstractForm(id) {
      if (id) {
        this.getAbstractInfoById(id)
      }
      this.visible = true
    },
    async getAbstractInfoById(id){
      const res = await getAbstractInfoById({id})
      if ( res.code === 0 ) {
        this.form = {
          id: res.data.id,
          author: res.data.author,
          title:  res.data.title,
          type:  (res.data.type === 0 || res.data.type) ? res.data.type : undefined,
          topic: (res.data.topic === 0 || res.data.topic) ? res.data.topic : undefined,
          country: res.data.country || undefined,
          city: res.data.city,
          affiliation: res.data.affiliation,
          email: res.data.email,
          mobile: res.data.mobile,
          countryCode: res.data.countryCode,
          documentInfo: res.data.documentInfo || [],
        }
        this.fileList = [
          {
            uid: '-1',
            name: res.data.documentInfo[0].fileName,
            status: 'done',
            url: res.data.documentInfo[0].fileUrl,
          }
        ]
      }
    },
    handleOk() {
      this.$refs.abstractForm.validate(valid => {
        if (!valid) {
          this.$nextTick(()=>{
            formModalWarnAndScroll(this.$refs.registForm);
          })
          return;
        }
        this.submitLoading = true
        if (this.form.id) {
          modifyAbstract(this.form).then((res)=> {
            if (res.code === 0 ) {
              this.handelClose()
            } else {
              this.$message.error(`${res.code}: ${res.msg}`)
            }
          }).catch(()=>{
            // this.$message.error(err.msg)
          }).finally(()=>{
            this.submitLoading = false
          })
        }else{
          addAbstract(this.form).then((res)=> {
            if (res.code === 0 ) {
              this.handelClose()
            } else {
              this.$message.error(`${res.code}: ${res.msg}`)
            }
          }).catch(()=>{
            // this.$message.error(err.msg)
          }).finally(()=>{
            this.submitLoading = false
          })
        }

      });
    },
    handelClose() {
      this.visible = false;
      this.$refs.abstractForm.resetFields()
      this.form.id = ""
      this.fileList = []
      console.log("closeHandle")
      this.$emit("handleCloseForm")
    },
    handleRemove() {
      this.form.documentInfo = []
      this.fileList = []
    },
    beforeUpload(file, fileList){
      // 判断
      console.log(file, fileList)
      if (file.type !== "application/pdf") {
        this.$message.error('You can only upload pdf file!');
        return false
      }
      this.uploading = true
      return true
    },
    changeUploadStatus(result) {
      console.log(result)
      this.fileList = result.fileList.slice(-1);
      if (result.file.status==="uploading") {
        this.uploading = false
        return
      }
      if (result.file.status==="done") {
        if(result.file.response.success){
          this.fileList = [
            {
              uid: '-1',
              name: result.file.response.data.fileName,
              status: 'done',
              url: result.file.response.data.fileUrl,
            }
          ]
          this.form.documentInfo = [{
            fileName: result.file.response.data.fileName,
            fileUrl: result.file.response.data.fileUrl
          }]
        }
        this.uploading = false
      }
    }
  }
}
</script>

<style lang="less"  scoped>
.add-submission {
  font-family: "Akrobat Black";
  h3 {
    color: #222;
    font-size: 18px;
  }
}
/deep/ .ant-form-item {
  margin-bottom: 19px;
}
/deep/ .ant-form-item-with-help {
  margin-bottom: 0;
}
/deep/ .ant-modal-body {
  padding-bottom: 12px;
}
</style>
