<template>
  <div class="myPage-container">
    <header-poster v-if="!screenWidthIsLe768()">
      <div class="header-poster-content">
        <div>ASCVTS 2024</div>
        <div>Welcome Message</div>
      </div>
    </header-poster>
    <div class="myPage-content dk:p-6 mb:p-4">
      <div class="title font-bold text-lg pb-3">
        My Page
      </div>
      <div class="tabbable">
        <div class="nav-list">
          <div class="nav-item" :class="{'active':navStatus==='Information'}" @click="navStatus='Information'">
            Sign-up Information
          </div>
          <div class="nav-item" :class="{'active':navStatus==='Submission'}" @click="navStatus='Submission'">
            Abstract Submission
          </div>
        </div>
      </div>
      <div class="myPage-main" v-show="navStatus==='Information'">
        <a-row class="myPage-row" v-for="(item, index) in userField" :key="index">
          <a-col :xs="7" :sm="7" :md="4" :lg="4" :xl="4" :xxl="4">
            <div class="infoKey font-bold text-sm py-2">
              {{item.label}}
            </div>
          </a-col>
          <a-col :xs="17" :sm="17" :md="20" :lg="20" :xl="20" :xxl="20">
            <div class="text-sm py-2 info dk:pl-4 mb:pl-2">
              {{data[item.key]}}
            </div>
          </a-col>
        </a-row>
        <div class="mt-12 mb-5 text-center">
          <a-button class="modify-btn" size="large" @click="$router.push({name: 'modifyInfo'})">Modify</a-button>
        </div>
      </div>
      <div class="submission-box" v-show="navStatus==='Submission'">
        <div class="submission-list">
          <div class="tr">
            <div class="td2">Presenting Type</div>
            <div class="td3">Presenting Author</div>
            <div class="td4">Abstract Title</div>
            <div class="td5">Status</div>
            <div class="td6">Tool</div>
          </div>
          <div class="tr" v-for="(item,i) in myAbstractList" :key="i">
            <div class="td2"><span>{{ typeMap[item.type] }}</span></div>
            <div class="td3"><span>{{ item.author }}</span></div>
            <div class="td4"><span>{{ item.title }}</span></div>
            <div class="td5"><span>{{ getCheckStatus(item.checkStatus) }}</span></div>
            <div class="td6">
              <a-button type="link" @click="showModal(item.id)">modify</a-button>
              <a-divider type="vertical" />
              <a-button type="link" @click="deleteAbstract(item.id)">delete</a-button>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-12 mb-5 text-center"  v-show="navStatus==='Submission'">
        <button type="button" class="modify-btn ant-btn ant-btn-lg" @click="showModal(null)">
          <span>Add</span>
        </button>
      </div>
    </div>
    <AbstrctForm v-if="showAbstractForm" ref="abstrctFormRef" @handleCloseForm="handleCloseForm"></AbstrctForm>
  </div>
</template>

<script>
  import { screenWidthIsLe768, getMemberInfo } from "@/utils/util";
  import {delAbstract, getMyAbstract} from '@/api/abstract'
  import AbstrctForm from "@/views/User/modules/AbstrctForm";
  /*const UserFieldsChina = [
    {label: 'Country', key: 'country'},
    {label: 'User ID (E-mail)', key: 'email'},
    {label: 'Name', key: 'realname'},
    {label: 'Year of Birth', key: 'birth'},
    {label: 'Title', key: 'title'},
    {label: 'Degree', key: 'degree'},
    {label: 'Category', key: 'category'},
    {label: 'Affiliation', key: 'site'},
    {label: 'Department', key: 'department'},
    {label: 'Specialty', key: 'specialty'},
    {label: 'Mobile Phone', key: 'mobile'}
  ];*/
  const UserFieldsEn = [
    {label: 'Country', key: 'country'},
    {label: 'User ID (E-mail)', key: 'email'},
    {label: 'First Name', key: 'firstname'},
    {label: 'Last Name', key: 'familyname'},
    {label: 'Year of Birth', key: 'birth'},
    {label: 'Title', key: 'title'},
    {label: 'Degree', key: 'degree'},
    {label: 'Category', key: 'category'},
    {label: 'Affiliation', key: 'site'},
    {label: 'Department', key: 'department'},
    {label: 'Specialty', key: 'specialty'},
    {label: 'Mobile Phone', key: 'mobile'}
  ];
  export default {
    name: "MyPage",
    components: {
      HeaderPoster: () => import('../../components/HeaderPoster'),
      AbstrctForm
    },
    created(){
      var flag = sessionStorage.getItem('abstract_submit')
      if ( flag ) {
        this.navStatus = 'Submission'
        sessionStorage.removeItem('abstract_submit')
      }
      this.getMyAbstract()
    },
    data() {
      return {
        userField: UserFieldsEn, // getMemberInfo()&&getMemberInfo()['country'] ==="China (中国)" ? UserFieldsChina : UserFieldsEn,
        screenWidthIsLe768,
        data: getMemberInfo(),
        navStatus: 'Information',
        myAbstractList: [],
        topicMap: [
          "Adult Cardiac", "Thoracic", "Pediatric", "Heart Failure"
        ],
        typeMap: [
          "Oral", "Poster"
        ],
        showAbstractForm: false
      }
    },
    methods :{
      showModal(id) {
        const that = this
        that.showAbstractForm = true;
        that.$nextTick(()=> {
          that.$refs.abstrctFormRef.initAbstractForm(id);
        })
      },
      deleteAbstract(id){
        const that = this;
        that.$confirm({
          content: "Are you sure?",
          okText: "Confirm",
          cancelText: "Concel",
          onOk: () => {
            delAbstract({id}).then((res)=>{
              if (res.code===0){
                that.getMyAbstract()
              }
            }).catch(()=>{

            }).finally(()=>{

            })
          }
        })
      },
      handleCloseForm() {
        this.showAbstractForm = false;
        this.getMyAbstract()
      },
      getCheckStatus(checkStatus) {
        if (checkStatus === 1) {
          return "Pass"
        }else if (checkStatus === 0){
          return "No pass"
        }else{
          return "To be reviewed"
        }
      },
      async getMyAbstract(){
        const res = await getMyAbstract()
        if ( res.code === 0 ) {
          this.myAbstractList = res.data
        }
      }
    }
  }
</script>

<style lang="less" scoped>
.myPage-container {
  background: #FFFFFF;
  .header-poster-content {

    & > div:first-child {
      color: #F8B301;
    }
    & > div:last-child {
      color: white;
    }
  }
  @media screen and (min-width: 768px) {
    .myPage-content {
      max-width: 768px;
      width: 768px;
      margin: 10px auto 60px auto!important;
    }
  }
  @media screen and (min-width: 820px) {
    .myPage-content {
      max-width: 780px;
      width: 780px;
      margin: 10px auto 60px auto!important;
    }
  }
  @media screen and (min-width: 992px) {
    .myPage-content {
      max-width: 900px;
      width: 900px;
      margin: 10px auto 60px auto!important;
    }
  }
  @media screen and (min-width: 1200px) {
    .myPage-content {
      max-width: 1013px;
      width: 1013px;
      margin: 10px auto 60px auto!important;
    }
  }
  @media screen and (max-width: 767px) {
    .myPage-content {
      width: 100%;
    }
  }
  .tabbable {
    .nav-list {
      display: flex;
      padding: 8px 0;
      .nav-item {
        padding: 8px;
        border-radius: 4px;
        font-size: 16px;
        transition: all .2s;
        background: #f5f7fa;
        color: #222;
        cursor: pointer;
        &.active {
          background: rgb(63,136,163) linear-gradient(73deg, rgba(63,136,163,1) 30%, rgba(88,46,104,1) 100%);
          color: #fff;
        }
        &:nth-child(n+2) {
          margin-left: 16px;
        }
      }
    }
  }
  .myPage-content {
    background: #F5F7FA;
    .mark-important {
      color: red;
    }
    .title {
      color: #222222;
      font-family: Akrobat;
      border-bottom: 1px solid #DEE2E6;
    }
    .myPage-main {
      .myPage-row + .myPage-row {
        margin-top: 5px;
      }
      .infoKey {
        font-family: Akrobat;
        color: #333333;
      }
      .info {
        background: white;
        border-left: 2px solid #F8B301;
        font-family: Akrobat-SemiBold, Akrobat;
        font-weight: 600;
        color: #19317E;
      }
    }
    .modify-btn {
      background: #F8B301;
      width: 300px;
      font-family: Akrobat-ExtraBold, Akrobat;
      font-weight: 600;
      color: #333333;
      border-color: #F8B301;
      &:hover {
        background: rgba(248, 179, 1, 0.8);
        border-color: rgba(248, 179, 1, 0.8);
      }
    }
    .submission-box {
      overflow-x: auto;
      .submission-list {
        min-width: 700px;
        border-top: 2px solid #F8B301;
        .tr {
          border-left: 1px solid #dee2e6;
          border-right: 1px solid #dee2e6;
          border-bottom: 1px solid #dee2e6;
          display: flex;
          font-size: 15px;
          text-align: center;
          &:nth-child(n+2) {
            background-color: #fff;
          }
          &>div {
            padding: 8px 0;
            display: flex;
            justify-content: center;
            align-items: center;
            &:nth-child(n+2) {
              border-left: 1px solid #dee2e6;
            }
          }
          .td2 {
            width: 15%;
            flex-shrink: 0;
          }
          .td3 {
            width: 20%;
            flex-shrink: 0;
          }
          .td4 {
            flex: 1;
          }
          .td5 {
            width: 15%;
            flex-shrink: 0;
          }
          .td6 {
            width: 22%;
            flex-shrink: 0;
          }
        }
      }
    }
  }
}

</style>
